<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="プロフィール" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">アカウント情報</h3>
            <div class="c-form__reqTxt l-item">：入力必須</div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>氏名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="profile.name"
                  inputType="text"
                  placeholder="氏名"
                  tooltip="必須項目です、12文字以内でご入力ください。"
                  :validation="validateMaxLen"
                  :validateArg="12"
                  :isValid.sync="validate.name.isValid"
                  :errMsg.sync="validate.name.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>メールアドレス</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="profile.mail"
                  inputType="text"
                  placeholder="メールアドレス"
                  tooltip="メールアドレス形式が正しくありません、入力内容をご確認ください。"
                  :validation="validateMail"
                  :isValid.sync="validate.mail.isValid"
                  :errMsg.sync="validate.mail.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="profile.tel"
                  inputType="number"
                  placeholder="電話番号"
                  tooltip="ハイフン無しの15桁以内の半角数字でご入力ください。"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.tel.isValid"
                  :errMsg.sync="validate.tel.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号（携帯）</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="profile.mobilePhone"
                  inputType="number"
                  placeholder="電話番号（携帯）"
                  tooltip="ハイフン無しの15桁以内の半角数字でご入力ください。"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.mobile.isValid"
                  :errMsg.sync="validate.mobile.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>所属先</label>
              </div>
              <div v-if="profile.groupInfo" class="c-inputWrap__items">
                {{ profile.groupInfo.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="l-contents l-box" v-if="useAuth=='false'">
          <div class="l-box">
            <h3 class="c-lead icon">ログイン情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>現在のパスワード</label>
              </div>
              <div class="c-inputWrap__items">
                <InputPassword
                    :value.sync="currentPassword"
                    name="currentPassword"
                    placeholder="現在のパスワード"
                    tooltip="半角英字と数字を組み合わせて8文字以上でご入力ください。"
                    :validation="validatePsw"
                    :isValid.sync="validate.currentPassword.isValid"
                    :errMsg.sync="validate.currentPassword.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>新規パスワード</label>
              </div>
              <div class="c-inputWrap__items">
                <InputPassword
                  :value.sync="newPassword"
                  name="newPassword"
                  placeholder="新規パスワード"
                  tooltip="半角英字と数字を組み合わせて8文字以上でご入力ください。"
                  :validation="validatePsw"
                  :isValid.sync="validate.newPassword.isValid"
                  :errMsg.sync="validate.newPassword.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>新規パスワード（確認）</label>
              </div>
              <div class="c-inputWrap__items">
                <InputPassword
                  :value.sync="confirmPassword"
                  name="confirmPassword"
                  placeholder="新規パスワード（確認）"
                  tooltip="半角英字と数字を組み合わせて8文字以上でご入力ください。"
                  :validation="validatePsw"
                  :isValid.sync="validate.confirmPassword.isValid"
                  :errMsg.sync="validate.confirmPassword.errMsg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler()">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="saveHandler">保存</div>
        </div>
      </div>
    </main>
    <div v-if="isShowModal" class="c-modal sm">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          プロフィールを修正します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="isShowModal = false">
            戻る
          </div>
          <div class="c-btn primary small" @click="setHandler">OK</div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import InputPassword from "@/components/parts/input/InputPassword";
import validation from "@/mixin/validation";
import {API_URL} from "@/const";

export default {
  name: "profile",
  components: {
    InputText,
    InputPassword,
  },
  mixins: [validation],
  data() {
    return {
      profile: {},
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      useAuth: process.env.VUE_APP_USE_AUTH,
      errMsgs: [],
      validate: {
        name: { isValid: true, errMsg: "" },
        mail: { isValid: true, errMsg: "" },
        tel: { isValid: true, errMsg: "" },
        mobile: { isValid: true, errMsg: "" },
        currentPassword: { isValid: true, errMsg: "" },
        newPassword: { isValid: true, errMsg: "" },
        confirmPassword: { isValid: true, errMsg: "" },
      },
      isShowModal: false,
    };
  },
  mounted() {
    // profile取得のAPI
    axios
      .get(API_URL.PROFILE.PROFILE)
      .then((res) => {
        this.profile = res.data;
      })
      .catch(() => {});
  },
  methods: {
    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
    saveHandler() {
      if (!this.runValidate()) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      this.isShowModal = true;
    },
    // アカウント情報とログイン情報のバリデーション
    runValidate() {
      this.errMsgs = [];
      const v = this.validate;
      let isAccountValid = true;
      let isPasswordValid = true;

      // アカウント情報変更のバリデーションチェック
      if (!v.name.isValid) {
        v.name.errMsg = "必須項目です、12文字以内でご入力ください。";
        isAccountValid = false;
      }
      if (!v.mail.isValid) {
        v.mail.errMsg = "メールアドレス形式が正しくありません、入力内容をご確認ください。";
        isAccountValid = false;
      }
      if (this.profile.tel && !v.tel.isValid) {
        v.tel.errMsg = "ハイフン無しの15桁以内の半角数字でご入力ください。";
        isAccountValid = false;
      }
      if (this.profile.mobilePhone && !v.mobile.isValid) {
        v.mobile.errMsg = "ハイフン無しの15桁以内の半角数字でご入力ください。";
        isAccountValid = false;
      }

      // パスワード変更のバリデーションチェック
      if (this.currentPassword || this.newPassword || this.confirmPassword) {
        if (!v.currentPassword.isValid) {
          v.currentPassword.errMsg = "半角英字と数字を組み合わせて8文字以上でご入力ください。";
          isPasswordValid = false;
        }
        if (!v.newPassword.isValid) {
          v.newPassword.errMsg = "半角英字と数字を組み合わせて8文字以上でご入力ください。";
          isPasswordValid = false;
        }
        if (!v.confirmPassword.isValid) {
          v.confirmPassword.errMsg = "半角英字と数字を組み合わせて8文字以上でご入力ください。";
          isPasswordValid = false;
        }
        if (this.currentPassword === this.newPassword) {
          v.newPassword.errMsg = "新規パスワードは現在のパスワードと異なる文字列をご入力ください。";
          v.newPassword.isValid = false;
          isPasswordValid = false;
        }
        if (this.confirmPassword && this.newPassword !== this.confirmPassword) {
          v.confirmPassword.errMsg = "新規パスワードと新規パスワード(確認)が異なります。";
          v.confirmPassword.isValid = false;
          isPasswordValid = false;
        }
      }

      if (!isAccountValid) this.errMsgs.push("アカウント情報の入力内容をご確認ください。");
      if (!isPasswordValid) this.errMsgs.push("ログイン情報の入力内容をご確認ください。");
      return isAccountValid && isPasswordValid;
    },
    setHandler() {
      const reqParam = {
        mail: this.profile.mail,
        name: this.profile.name,
        numberPhone: this.profile.tel ? this.profile.tel : null,
        mobilePhone: this.profile.mobilePhone ? this.profile.mobilePhone : null,
      };

      // パスワード変更する場合のみ、パラメータにセット
      if (this.currentPassword && this.newPassword) {
        reqParam.currentPassword = this.currentPassword;
        reqParam.newPassword = this.newPassword;
      }

      axios
        .put(API_URL.PROFILE.PROFILE, reqParam)
        .then(() => {
          this.$router.push("/profile");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isShowModal = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
  },
};
</script>

<style scoped>
.text-psw {
  border: none;
  background-color: transparent;
}
.title-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
